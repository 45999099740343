import React, { useState, useRef, useEffect } from "react";
import { useOutsideClick } from "../hooks/useOutsideClick";

const PropertySearch = ({ applyFilters }) => {
  const { ref, isVisible, setIsVisible } = useOutsideClick(false);
  const [searchText, setSearchText] = useState("");
  const searchInputRef = useRef(null);

  useEffect(() => {
    if (isVisible && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isVisible]);

  const toggleSearch = () => {
    setIsVisible(!isVisible);
  };

  const handleInputChange = (event) => {
    const newSearchText = event.target.value;
    setSearchText(newSearchText);

    if (newSearchText.length >= 2) {
      applyFilters({ name: newSearchText });
    } else if (newSearchText.length === 0) {
      applyFilters({ name: "" });
    }
  };

  const classDiv = `filter_search ${isVisible ? "open" : ""}`;

  return (
    <>
      <div className="filter_search-toggle" onClick={toggleSearch}>
        <svg className="icon icon-search">
          <use xlinkHref="#icon-search"></use>
        </svg>
      </div>

      {isVisible && (
        <div ref={ref} className={classDiv}>
          <svg className="icon icon-search-active">
            <use xlinkHref="#icon-search"></use>
          </svg>
          <div className="search-form">
            <input
              ref={searchInputRef}
              className="search-text"
              id="search-text"
              type="search"
              name="s"
              placeholder="Search specific hotels"
              autoComplete="off"
              value={searchText}
              onChange={handleInputChange}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default PropertySearch;
