import React from "react";
import { Checkbox, ConfigProvider } from "antd";

export default function PropertyMeta({
  currentPage,
  props,
  hasFilters,
  clearFilters,
  setOpenFilterMobile,
  openFilterMobile,
  setMapHidden,
  showSoldOut,
  totalProps,
  totalProperties,
  urlFilters,
  showSoldOutValue,
  allowPagination
}) {
  const handleShowSoldOut = (e) => {
    showSoldOut(e.target.checked);
  };

  return (
    <div className="property-meta">
      <span className="property-meta_total">
        {totalProps ? (
          !allowPagination ? (
            <>{`Showing ${totalProperties} places to stay`}</>
          ) : (
            <>
              {`Showing ${currentPage * 20 - (20 - 1)} - ${
                currentPage * 20 - (20 - props?.length)
              } of ${totalProperties} places to stay`}
            </>
          )
        ) : (
          "There are no places available. Try changing dates and/or filters."
        )}
      </span>

      <div>
        <ConfigProvider
          theme={{
            components: {
              Checkbox: {
                colorPrimary: globalSettings.single_hotel_pin_color,
              },
            },
          }}
        >
          <Checkbox defaultChecked={showSoldOutValue} onChange={handleShowSoldOut}>Show Sold Out</Checkbox>
        </ConfigProvider>
      </div>

      {hasFilters && (
        <span className="property-meta_clear" onClick={() => clearFilters()}>
          Clear filters
        </span>
      )}

      <div className="main-search_mobile-filters">
        <span
          className="mobile-filter_filters"
          onClick={() => setOpenFilterMobile(!openFilterMobile)}
        >
          <button type="button">
            {/*Filters*/}
            <svg
              fill="currentColor"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              x="0"
              y="0"
              viewBox="0 0 32 32"
              xmlSpace="preserve"
            >
              <style></style>
              <path d="M16 19a1 1 0 0 1-1-1V4a1 1 0 1 1 2 0v14a1 1 0 0 1-1 1zM16 29a1 1 0 0 1-1-1v-4a1 1 0 1 1 2 0v4a1 1 0 0 1-1 1z" />
              <path d="M16 25c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm0-6c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2zM6 13a1 1 0 0 1-1-1V4a1 1 0 1 1 2 0v8a1 1 0 0 1-1 1zM6 29a1 1 0 0 1-1-1V18a1 1 0 1 1 2 0v10a1 1 0 0 1-1 1z" />
              <path d="M6 19c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm0-6c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2zM26 10a1 1 0 0 1-1-1V4a1 1 0 1 1 2 0v5a1 1 0 0 1-1 1zM26 29a1 1 0 0 1-1-1V15a1 1 0 1 1 2 0v13a1 1 0 0 1-1 1z" />
              <path d="M26 16c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm0-6c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2z" />
            </svg>
          </button>
        </span>
        <span className="mobile-filter_map">
          <button type="button" onClick={() => setMapHidden(false)}>
            {/*Map*/}
            <svg
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
            >
              <g id="maps">
                <path
                  className="cls-1"
                  d="m29 6.94-8.52-2.83h-.06A1 1 0 0 0 20 4a.89.89 0 0 0-.43.11h-.08l-2.85 1.08a5 5 0 0 0-1-1.59 5 5 0 0 0-7.9.77 4.87 4.87 0 0 0-.47 1L3.94 4.26A1.47 1.47 0 0 0 2 5.66V7a1 1 0 0 0 2 0v-.61l3 1a5 5 0 0 0 .51 1.87l3.57 7.19a1 1 0 0 0 1.8 0l3.57-7.19A5.06 5.06 0 0 0 17 7.41a1.47 1.47 0 0 0 0-.21l2-.76v18.87l-6 2.25V20a1 1 0 0 0-2 0v7.61l-7-2.33V11a1 1 0 0 0-2 0v14.66a1.48 1.48 0 0 0 1 1.4l8.51 2.83h.07A.92.92 0 0 0 12 30a1 1 0 0 0 .44-.11h.07L20 27.06l2.66.89a1 1 0 0 0 .64-1.9l-2.3-.77V6.39l7 2.33v18.89l-.68-.23a1 1 0 0 0-.64 1.9l1.38.46a1.48 1.48 0 0 0 .46.08 1.53 1.53 0 0 0 .87-.28 1.5 1.5 0 0 0 .61-1.2v-20a1.48 1.48 0 0 0-1-1.4zM14.68 8.37 12 13.75 9.32 8.37a3 3 0 0 1 .14-2.95A3 3 0 0 1 14.19 5a3.07 3.07 0 0 1 .8 2.3 3.18 3.18 0 0 1-.31 1.07z"
                />
                <path
                  className="cls-1"
                  d="M12 6a1 1 0 1 0 1 1 1 1 0 0 0-1-1z"
                />
              </g>
            </svg>
          </button>
        </span>
      </div>

      {globalSettings.publisher_phone?.length > 0 && (
        <span className="property-meta_contact">
          <a href={`tel: ${globalSettings.phone_number}`}>
            <svg className="icon icon-smartphone">
              <use xlinkHref="#icon-smartphone"></use>
            </svg>
            <span>
              {globalSettings.phone_number_call_out}{" "}
              {globalSettings.phone_number}
            </span>
          </a>
        </span>
      )}
    </div>
  );
}
