import React, { useEffect, useState } from "react";
import axios from "axios";
import SearchBar from "../components/search-bar";
import PropertyFilters from "../components/property-filters";
import PropertyMeta from "../components/property-meta";
import Pagination, { paginateProperties } from "../components/pagination";
import PropertyCard from "../components/property-card";
import Map from "../components/map";
import { useProperties } from "../context/properties.context";
import sortProperties from "../utils/sortProperties";
import { getHeadersHeight } from "../utils/getHeaderHeight";
import { useLoading } from "../context/loading.context";

export default function srpLayout({
  page = 1,
  perPage,
  properties,
  discounts,
  showModal,
}) {
  const {
    propsIsLoading,
    refetch,
    $session,
    filters,
    urlFilters,
    totalProperties,
    allowPagination,
    showSoldOut: _showSoldOut,
  } = useProperties();

  const [hideMap, setHideMap] = useState(false);
  const [currentPage, setCurrentPage] = useState(page || 1);
  const [props, setProps] = useState(properties);
  const [filtered, setFiltered] = useState([]);
  const [openFilterMobile, setOpenFilterMobile] = useState(false);
  const [hasFilters, setHasFilters] = useState(false);
  const [clear, setClear] = useState(false);
  const [activeFilters, setActiveFilters] = useState(null);
  const { setIsLoading } = useLoading();
  const [showSoldOut, setShowSoldOut] = useState(_showSoldOut);
  const [totalProps, setTotalProps] = useState(totalProperties);

  useEffect(() => {
    if (properties.length) {
      if (!filtered.length && !activeFilters) {
        const source = !showSoldOut
          ? properties.filter((prop) => prop.is_available)
          : properties;
        setProps(() =>
          !allowPagination
            ? source
            : paginateProperties(source, currentPage, perPage)
        );
        setTotalProps(() => (!showSoldOut ? source.length : totalProperties));
      } else {
        const source = !showSoldOut
          ? filtered.filter((prop) => prop.is_available)
          : filtered;
        setProps(() =>
          !allowPagination
            ? source
            : paginateProperties(source, currentPage, perPage)
        );
        setTotalProps(() => (!showSoldOut ? source.length : totalProperties));
      }
    }
  }, [properties, currentPage, showSoldOut, filtered]);

  useEffect(() => {
    setHideMap(() => window.innerWidth < 600);
    window.addEventListener("resize", () =>
      setHideMap(() => window.innerWidth < 600)
    );

    return window.removeEventListener("resize", () =>
      setHideMap(() => window.innerWidth < 600)
    );
  }, []);

  useEffect(() => {
    if (
      Object.keys(urlFilters).length &&
      Object.keys(urlFilters).some((f) =>
        [
          "features",
          "property_types",
          "amenities",
          "rating",
          "sort_by",
          "areas",
        ].includes(f)
      )
    ) {
      handleApplyFilters(urlFilters);
    }
  }, [urlFilters, propsIsLoading]);

  const propertiesList = () => {
    return props?.map((prop, idx) => {
      return (
        <PropertyCard property={prop} key={`prop-${idx}`} filters={filters} />
      );
    });
  };

  const clearFilters = () => {
    setClear(true);
    setHasFilters(false);
    setCurrentPage(1);
    setProps(() =>
      !allowPagination
        ? properties
        : paginateProperties(properties, 1, perPage)
    );
    setFiltered(properties);
  };

  const updateFeaturesFilter = (selectedFeatures, filteredProperties) => {
    let combinedFeatures;

    if (selectedFeatures === undefined) {
      combinedFeatures = new Set([...(activeFilters?.features || [])]);
    } else if (selectedFeatures && selectedFeatures.length === 0) {
      combinedFeatures = new Set([...(selectedFeatures || [])]);
    } else {
      combinedFeatures = new Set([
        ...(selectedFeatures || []),
      ]);
    }

    if (combinedFeatures && Array.from(combinedFeatures).length === 0) {
      setActiveFilters((prevFilters) => ({
        ...prevFilters,
        features: [],
      }));
      return filteredProperties;
    }

    const newFilteredProperties = filteredProperties.filter((property) =>
      Array.from(combinedFeatures).every((feature) =>
        property.filter.includes(feature)
      )
    );

    setActiveFilters((prevFilters) => ({
      ...prevFilters,
      features: Array.from(combinedFeatures),
    }));

    return newFilteredProperties;
  };

  const updatePropertyTypesFilter = (selectedFeatures, filteredProperties) => {
    let combined;
    if (selectedFeatures === undefined) {
      combined = new Set([...(activeFilters?.property_types || [])]);
    } else if (selectedFeatures && selectedFeatures.length === 0) {
      combined = new Set([...(selectedFeatures || [])]);
    } else {
      combined = new Set([
        ...(selectedFeatures || []),
      ]);
    }

    let arrayCombined = Array.from(combined);
    if (arrayCombined && arrayCombined.length === 0) {
      setActiveFilters((prevFilters) => ({
        ...prevFilters,
        property_types: [],
      }));
      return filteredProperties;
    }

    const newFilteredProperties = filteredProperties.filter((property) =>
      arrayCombined.includes(property.property_type?.id)
    );

    setActiveFilters((prevFilters) => ({
      ...prevFilters,
      property_types: arrayCombined,
    }));
    return newFilteredProperties;
  };

  const updateAmenitiesFilter = (selectedFeatures, filteredProperties) => {
    let combined;
    if (selectedFeatures === undefined) {
      combined = new Set([...(activeFilters?.amenities || [])]);
    } else if (selectedFeatures && selectedFeatures.length === 0) {
      combined = new Set([...(selectedFeatures || [])]);
    } else {
      combined = new Set([
        ...(selectedFeatures || []),
      ]);
    }

    let arrayCombined = Array.from(combined);
    if (arrayCombined && arrayCombined.length === 0) {
      setActiveFilters((prevFilters) => ({
        ...prevFilters,
        amenities: [],
      }));
      return filteredProperties;
    }

    const newFilteredProperties = filteredProperties.filter((property) =>
      property.amenity.some((id) => arrayCombined.includes(id))
    );

    setActiveFilters((prevFilters) => ({
      ...prevFilters,
      amenities: arrayCombined,
    }));

    return newFilteredProperties;
  };

  const updateRatingFilter = (selectedFeatures, filteredProperties) => {
    let combined;
    if (selectedFeatures === undefined) {
      combined = new Set([...(activeFilters?.rating || [])]);
    } else if (selectedFeatures && selectedFeatures.length === 0) {
      combined = new Set([...(selectedFeatures || [])]);
    } else {
      combined = new Set([
        ...(selectedFeatures || []),
      ]);
    }

    let arrayCombined = Array.from(combined);
    if (arrayCombined && arrayCombined.length === 0) {
      setActiveFilters((prevFilters) => ({
        ...prevFilters,
        rating: [],
      }));
      return filteredProperties;
    }

    const newFilteredProperties = filteredProperties.filter((property) => {
      let val = property.property_rating?.stars?.value;
      let values = [Math.trunc(val), Math.ceil(val)];
      return values.some((id) => arrayCombined.includes(id));
    });

    setActiveFilters((prevFilters) => ({
      ...prevFilters,
      rating: arrayCombined,
    }));

    return newFilteredProperties;
  };

  const updateAreasFilter = (selectedFeatures, filteredProperties) => {
    let combined;
    if (selectedFeatures === undefined) {
      combined = new Set([...(activeFilters?.areas || [])]);
    } else if (selectedFeatures && selectedFeatures.length === 0) {
      combined = new Set([...(selectedFeatures || [])]);
    } else {
      combined = new Set([
        ...(selectedFeatures || []),
      ]);
    }

    let arrayCombined = Array.from(combined);
    if (arrayCombined && arrayCombined.length === 0) {
      setActiveFilters((prevFilters) => ({
        ...prevFilters,
        areas: [],
      }));
      return filteredProperties;
    }

    const newFilteredProperties = filteredProperties.filter((property) => {
      return property.destination?.areas?.some((area) =>
        arrayCombined.includes(area.id)
      );
    });

    setActiveFilters((prevFilters) => ({
      ...prevFilters,
      areas: arrayCombined,
    }));

    return newFilteredProperties;
  };

  const handleApplyFilters = ({
    features,
    property_types,
    amenities,
    rating,
    sort_by,
    name,
    areas,
  }) => {
    setClear(false);
    if (propsIsLoading) {
      setIsLoading(true);
      return;
    }

    setIsLoading(false);

    let filteredProperties = structuredClone(properties);
    let filters = false;

    filteredProperties = updateFeaturesFilter(features, filteredProperties);
    filteredProperties = updatePropertyTypesFilter(
      property_types,
      filteredProperties
    );
    filteredProperties = updateAmenitiesFilter(amenities, filteredProperties);
    filteredProperties = updateRatingFilter(rating, filteredProperties);
    filteredProperties = updateAreasFilter(areas, filteredProperties);

    if (sort_by && sort_by.length > 0) {
      filteredProperties = sortProperties(filteredProperties, sort_by);
      filters = true;
    }

    if (name && name !== "") {
      filteredProperties = filteredProperties.filter((property) =>
        property.name.toLowerCase().includes(name.toLowerCase())
      );
      filters = true;
    }


    setHasFilters(filters);
    setCurrentPage(1);
    setProps(() =>
      !allowPagination
        ? filteredProperties
        : paginateProperties(filteredProperties, 1, perPage)
    );
    setFiltered(filteredProperties);
  };

  function handleApplyDatesOccupancy({ checkin, checkout, units }) {
    setFiltered(0);
    refetch({
      checkin,
      checkout,
      units,
    });
  }

  function handleShowSoldOut(hide) {
    setShowSoldOut(hide);
  }

  const formatNumber = (number) => {
    return number.toLocaleString(); // Make sure to implement this correctly
  };

  return (
    <div id="content" className="site-content">
      <main className="main-content">
        <SearchBar handleApplyDatesOccupancy={handleApplyDatesOccupancy} />

        <PropertyFilters
          clearFilters={clearFilters}
          filters={filters}
          clear={clear}
          applyFilters={handleApplyFilters}
          openFilterMobile={openFilterMobile}
          setOpenFilterMobile={setOpenFilterMobile}
        />

        <PropertyMeta
          clearFilters={clearFilters}
          props={props}
          currentPage={currentPage}
          perPage={perPage}
          hasFilters={hasFilters}
          openFilterMobile={openFilterMobile}
          setOpenFilterMobile={setOpenFilterMobile}
          setMapHidden={setHideMap}
          showSoldOut={handleShowSoldOut}
          showSoldOutValue={showSoldOut}
          totalProps={totalProps}
          totalProperties={totalProperties}
          urlFilters={urlFilters}
          allowPagination={allowPagination}
        />

        {discounts?.length > 0 &&
          !$session.promoCode &&
          !urlFilters?.PromoCode && (
            <div
              className="discounts-banner"
              onClick={
                discounts.length > 1
                  ? () => showModal()
                  : () => refetch({ promoCode: discounts[0].code })
              }
            >
              <p>
                Your stay qualifies for a&nbsp;
                {discounts.length === 1 ? (
                  <strong>{discounts[0].title}</strong>
                ) : (
                  <span>
                    free gift. <strong>Choose yours!</strong>
                  </span>
                )}
              </p>
            </div>
          )}

        <ul className="property-search-list" id="property-search-list">
          {props && propertiesList()}
        </ul>
        {properties && !(!allowPagination) && (
          <div
            className={`pagination-wrapper ${
              !(
                document.querySelector("#drift-frame-controller") ||
                document.querySelector("#drift-frame-chat")
              )
                ? "full-width"
                : ""
            }`}
          >
            <Pagination
              page={currentPage}
              totalProps={totalProps}
              changePage={(page) => setCurrentPage(page)}
            />
            <div
              className="list-pagination_desc"
              style={{ marginBottom: "10px" }}
            >
              {totalProps ? (
                !allowPagination ? (
                  <>{`Showing ${totalProperties} places to stay`}</>
                ) : (
                  <>
                    {`Showing ${currentPage * 20 - (20 - 1)} - ${
                      currentPage * 20 - (20 - props?.length)
                    } of ${totalProperties} places to stay`}
                  </>
                )
              ) : (
                "There are no places available. Try changing dates and/or filters."
              )}
            </div>
          </div>
        )}
      </main>
      {!hideMap && (
        <aside
          className="sidebar map"
          style={{ bottom: 0, top: getHeadersHeight() + "px" }}
        >
          <button className="map-close" onClick={() => setHideMap(true)}>
            <svg className="icon ">
              <use xlinkHref="#icon-x"></use>
            </svg>
          </button>
          <Map
            locations={props?.map((prop) => ({
              ...prop,
              position: {
                lat: prop?.physical_address?.latitude * 1,
                lng: prop?.physical_address?.longitude * 1,
              },
              label: formatNumber(Math.trunc(prop.rate.low.total.nightly_avg)),
              isProperty: true,
            }))}
          />
        </aside>
      )}
    </div>
  );
}
