import React from "react";
import { Tooltip } from "antd";

// Asume la existencia de componentes y funciones de formato necesarios
const PriceCard = ({ property, globalSettings, session }) => {
  // Helper function to format number
  const formatNumber = (number) => {
    return number.toLocaleString(); // Make sure to implement this correctly
  };

  const renderOffer = () => {
    if (!property.price_filtered) {
      const hasPromotion = property.rate.low.promotion !== false;
      const hasDiscount = property.rate.low.discount !== false;
      const discountTooltip =
        hasDiscount && property.rate.low.discount.tooltip_string.length > 0;

      return (
        <div className="offer offer-property-class">
          {(hasPromotion || hasDiscount) && (
            <figure>
              <div className="offer-content">
                {hasPromotion && (
                  <span style={{display: 'block'}}>{property.rate.low.promotion.display_string}</span>
                )}
                {hasPromotion && hasDiscount && <span> + </span>}
                {hasDiscount && (
                  <div style={{display: 'flex', gap: '5px'}}>
                    <span>{property.rate.low.discount.display_string}</span>
                    {discountTooltip && (
                      <Tooltip title={property.rate.low.discount.tooltip_string} placement="bottom">
                        <svg
                          height="15px"
                          width="15px"
                          style={{position: 'initial'}}
                          className="icon icon-info"
                        >
                          <use xlinkHref="#icon-info"></use>
                        </svg>
                      </Tooltip>
                    )}
                  </div>
                )}
              </div>
            </figure>
          )}
        </div>
      );
    }

    return null;
  };

  const renderInventoryCount = () => {
    if (
      property.rate.low.total.cumulativeInventory &&
      property.rate.low.total.showInventoryCount
    ) {
      return (
        <span style={{ fontSize: "medium", fontWeight: "350" }}>
          {property.rate.low.total.cumulativeInventory} available
        </span>
      );
    }
  };

  const renderPriceInformation = () => {
    const hasTaxes = property.rate.low.total.tax >= 0;
    const hasFees = property.rate.low.total.fee >= 0;

    if (property.is_available === true) {
      return (
        <>
          {!property.price_filtered ? (
            <div className="price-info">
              {property.rate.low.total.original_nightly_avg >
                property.rate.low.total.nightly_avg && (
                <span className="fee-price strikethrough-price">
                  <span>per night: $</span>
                  {formatNumber(
                    Math.trunc(property.rate.low.total.original_nightly_avg)
                  )}
                </span>
              )}

              {property.rate.low.total.nightly_avg && (
                <span className="price">
                  <span className="fee-price" style={{display: 'block', textAlign: 'center'}}>per night</span>
                  ${formatNumber(
                    Math.trunc(property.rate.low.total.nightly_avg)
                  )}
                </span>
              )}

              {/* {(hasTaxes || hasFees) && (
                <span className="fee-price">
                  <span>+ taxes and fees: $</span>
                  {formatNumber(
                    Math.trunc((property.rate.low.total.tax || 0) + (property.rate.low.total.fee || 0))
                  )}
                </span>
              )} */}

              {property.rate && 
              property.rate.low && 
              property.rate.low.total && 
              typeof property.rate.low.total.subtotal_with_taxes === 'number' && 
              property.rate.low.total.subtotal_with_taxes !== 0 && (
                <span className="fee-price">
                  <span className="currency">total stay with fees: $</span>
                  {formatNumber(
                    Math.trunc(property.rate.low.total.subtotal_with_taxes)
                  )}
                </span>
              )}
            </div>
          ) : (
            <span>{property.unavailable_reason}</span>
          )}

          {renderInventoryCount()}
          {renderOffer()}
        </>
      );
    } else if (property.unavailable_reason?.toString() !== "false") {
      return (
        <span className="unavailable_reason">
          {property.unavailable_reason}
        </span>
      );
    } else if (globalSettings.soldout_text) {
      return <>{globalSettings.soldout_text}</>;
    } else {
      return <span className="soldOutPlain">Sold Out</span>;
    }
  };

  return (
    <div className="property-card_price-book">
      <div
        id={`priceWrapProperty${property.id}`}
        className="price-wrap"
        style={{ textAlign: "center" }}
      >
        {renderPriceInformation()}
      </div>
      {property.is_available && session?.rooms?.length > 1 && (
        <span className="price-per-room">per night per unit</span>
      )}
    </div>
  );
};

export default PriceCard;
